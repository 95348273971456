import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IChartsState {
  xAxisLabels: IXAxisLabel[];
  chartsLoading: boolean;
  dataSetLength: number;
  cumulative: boolean;
}

const initialState: IChartsState = {
  xAxisLabels: [],
  chartsLoading: false,
  dataSetLength: 0,
  cumulative: false
} as IChartsState;

export const chartsSlice = createSlice({
  name: "charts",
  initialState,
  reducers: {
    setXAxisLabels: (state, action: PayloadAction<IXAxisLabel[]>) => ({
      ...state,
      xAxisLabels: action.payload
    }),
    setChartsLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      chartsLoading: action.payload
    }),
    setDataSetLength: (state, action: PayloadAction<number>) => ({
      ...state,
      dataSetLength: action.payload
    }),
    setCumulative: (state, action: PayloadAction<boolean>) => ({
      ...state,
      cumulative: action.payload
    })
  }
});

export const {
  setXAxisLabels,
  setChartsLoading,
  setDataSetLength,
  setCumulative
} = chartsSlice.actions;

export default chartsSlice.reducer;
