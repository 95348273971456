import { configureStore } from "@reduxjs/toolkit";

import chartsReducer from "./reducers/charts";

export const makeStore = () => {
  return configureStore({
    reducer: {
      charts: chartsReducer
    }
  });
};

// Infer the `RootState`,  `AppDispatch`, and `AppStore` types from the store itself
export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
